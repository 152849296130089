@import url(https://fonts.googleapis.com/css?family=EB%20Garamond|Inconsolata|Nunito);

* {
  box-sizing: border-box;
}

h1, h2 {
  font-family: 'EB Garamond', serif;
}

body {
  margin: 0;
  color: #474747;
  font-style: normal;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
